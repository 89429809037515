body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



:root {
  --background: #fafafa;
  --text-primary: #191919;
  --text-secondary: #0F353D;
  --accent: #0C7D74;
  --post-bk: #ffffff;
  --clr-1: #383416;
  --clr-2: #0C7D74;
  --clr-3: #FFAE00;
  --clr-4: #0C7D74;
  --clr-5: #634A00;
  --icn: #634A00;
  --dltIcn: #6F1D1A;
  --borders: lightgray;
  --siteId: #fafafa;
  --avitarBk: #fafafa;
  --avitarTxt: #fafafa;
  --thcIcnBrdr: #191919;
  --cbdIcnBrdr: #191919;
  --trpIcnBrdr: #191919;
}
[data-theme='dark'] {
  --background: #191919;
  --text-primary: #e9ecba;
  --text-secondary: grey;
  --accent: #FFAE00;
  --post-bk: #222;
  --clr-1: #383416;
  --clr-2: #0F353D;
  --clr-3: #D89216;
  --clr-4: #0F353D;
  --clr-5: #634A00;
  --icn: #e9ecba;
  --dltIcn: #db2c25;
  --borders: #111111; 
  --siteId: #e9ecba;
  --avitarBk: #e9ecba;
  --avitarTxt: #e9ecba;
  --thcIcnBrdr: #191919;
  --cbdIcnBrdr: #191919;
  --trpIcnBrdr: #191919;
}
