.imageUpload {
    display: flex;
    flex-direction: column;
    max-width:500px;
    margin-left: auto;
    margin-right: auto;
    background-color: #ffffff;
    padding-top:7px;
} 

.uploadcare--widget__button_type_open, .uploadcare--dialog .uploadcare--button {
    border: 2px solid var(--clr-5);
    color: var(--clr-5);
    background: transparent;
}
.uploadcare--widget__button_type_open:hover, .uploadcare--dialog .uploadcare--button:hover {
    border: 2px solid var(--clr-1);
    color: var(--siteId);
    background: var(--clr-5);
}
.uploadcare--dialog .uploadcare--menu__item:hover {color: var(--clr-5)}
.imageupload_button {margin-bottom:14px !important; margin-top:14px !important; border-width: 2px !important;}
.uploadcare--dialog .uploadcare--progress_type_canvas {
    color: var(--clr-5);
    border-color: var(--clr-1);
  }


.css-79ws1d-MuiModal-root div {overflow:hidden !important;}

.image_uploads {
    display:flex;
    flex-direction: column;
  }

.fiftyFifty {padding:14px 0 4px 0; display: flex; flex-direction: row; justify-content: space-between; align-items: center; gap:14px;}
.fiftyL {}
.fiftyR {}

.textPrompt {padding-bottom:7px; font-weight:bold;}
.coaHead, .imgHead {text-align: center; font-weight:bold; margin-bottom:14px; border-bottom:2px solid var(--clr-5);}