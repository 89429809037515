@font-face {
  font-family: 'Futura XBlkCnIt BT';
  src: url('./fonts/FuturaBT-ExtraBlackCondItalic.woff2') format('woff2'),
      url('./fonts/FuturaBT-ExtraBlackCondItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* font-family: "Open Sans";
  font-weight: 400; */
}

* {
  margin:0px;
}

.app {
  background-color: var(--background);
  color:var(--text-primary);
  transition: all .5s;
  
}

.app_posts {
  padding:20px;
  
}

.app_header {
  position:sticky;
  top:0;
  z-index:10000;
  background-color:var(--post-bk);
  border-bottom: 1px solid lightgray;
  object-fit: contain;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding:0px 20px 0px 0px;
  transition: all .5s;
}

.app_header_image {
  object-fit: contain;
  width:200px;
}

.app_signin {
  display:flex;
  flex-direction: column;
}

.app_signin .fcntrl {margin-top:14px;}

.siteId {
  color:var(--siteId); 
  background-color:var(--clr-5); 
  font-family: 'Futura XBlkCnIt BT';
  font-weight: 900; 
  font-style: italic;
  grid-column-start: 1;
  grid-column-end: 2;
}

.leftBox {
  display: grid;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;

}
.siteTitle {border-left:1px solid #fafafa; padding-left:14px;}
.sndBtn {
  grid-column-start: 3;
  grid-column-end: 3;
  background-color:var(--post-bk);}
.send_likes svg path, .thmSwitch svg path, .signInUp svg path, .signOut svg path, .introLink svg path {transition: all .5s;  fill: var(--icn);}
.thmSwitch {grid-column-start: 2; grid-column-end: 2;}


.paper, .likeListv1Box, .likeListv2Box, .app_likesv1, .userWantsWrap {background-color: var(--background);}
.paper form {width:80%; margin:0 auto;}

/* .uploadBox {display: flex; justify-content: center; width:100%;} */

.likeListv1Box, .likeListv2Box, .likeListv1BoxA, .likeListv1BoxB, .likeListv1BoxC, .likeListv1BoxD {
  display:flex;
}
.likeListV1Header, .likeListV2Header, .userWantsWrap {padding:14px;}
.likeListv1Box {padding:7px;}
.likeListv1BoxA {padding:7px;}
.likeListv1BoxB {padding:7px; flex-direction: column;}
.likeListv1BoxC {font-size:1.3rem;}
.likeListv1BoxD {gap: 2px;}
.likev1thc, .likev1cbd, .likev1trp{padding:3px; color:#fff;}
.likev1thc {background-color: var(--clr-1);}
.likev1cbd {background-color: var(--clr-2);}
.likev1trp {background-color: var(--clr-3);}

.wantlist {display:flex; flex-direction: column; justify-content: center; padding:7px;}
.userWantsWrap {margin-bottom:14px;}
.userWantsWrap, .wantsProductWrap {display:flex; gap:7px; flex-direction: column;}
.wpNameWrap {display:flex; gap:7px;}
.wanterDetails {display:flex; gap: 7px;}
.wdAvatar, .wpAvatar {display:flex; align-items: center; justify-content: center;}
.wdName, .wpName {}
.wpListWrap {}

.switchBox,.inProgressWrap {display:flex; flex-direction:row; gap:4px; align-items: center; justify-content: flex-end;}
.inProgressWrap p {display:inline-block;}
.switchBox { background-color:var(--clr-5); border-radius:5px; padding:2px 7px 2px 7px; color:#fff; font-weight:bold;}

.sendWants {background-color:var(--clr-5); display:flex; justify-content: flex-end;}
.send svg {animation: pulse 2s ease-out infinite;}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  3.3% {
    transform: scale(1.1);
  }
  16.5% {
    transform: scale(1);
  }
  33% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

/* LANDING PAGE STYLES */
#homeWrap {padding-top:3rem;}
#heroStatement, #powerStatement, #lookStatement, #lookCards, #heroCTA {
  display:flex; flex-direction: column; align-items: center; justify-content: center; padding:14px;
}
.introLink {font-size:1.3rem; color: var(--text-primary); display:flex; flex-direction:row; gap:4px; align-items: center; justify-content: center;}
.introHead, #powerStatement, #lookStatement, #heroCTA {padding-top: 2rem;}
.introHead h1, .powerHead h2, .lookHead h2 {color: var(--text-primary); text-transform: uppercase; text-align: center; font-weight:900;}
.introHead h1 span, .powerHead h2 span {color: var(--clr-5);}
.introTxt, .powerTxt, .lookTxt {text-align: center; padding-top:2rem; font-size:1.25rem; font-weight:bold; line-height:1.75rem; opacity:.8;}

#lookStatement {}
.lookHead {}
.lookHead h2 {}
.lookTxt {}

#lookCards {min-height:500px;}

#powerStatement {}
.powerTxt {}
.powerHead h2 {}
.powerTxt span {line-height:2.5rem;}

/* FANNED CARDS STYLES */

.main{
  position: relative;
  /* display: block; */
  margin: 0 auto;
  width: 1px;
  top:150px;
  left:-100px;
}

.container{
  display: block;
  width: 200px;
  height: 310px;
  position: absolute;
  bottom:0;
  animation-duration: 0.5s;
  animation-delay:1s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  transition: height 0.4s;
}

.container:hover{
  height:350px;
}

.card{
  display: block;
  width: 224px;
  height: 310px;
  position: absolute;
  top:0;
  background-size:100%;
  background-repeat:no-repeat;
  border: 1px solid #b37412;
  border-radius: 20px;
  overflow:hidden;
  box-shadow: 0px 5px 5px 2px rgba(0, 0, 0, 0.3);
}

.cardA {Background-image: url('https://ucarecdn.com/2afc45f1-001d-4869-94c3-ca3317dac330/screenshot1.jpg');
}
.cardB {Background-image: url('https://ucarecdn.com/4fbfebed-38eb-428b-b35a-d0e2c5571687/screenshot2.jpg');
}
.cardC {Background-image: url('https://ucarecdn.com/d2a339fc-0abf-48a8-963b-27018cdc47b6/screenshot3.jpg');
}
.cardD {Background-image: url('https://ucarecdn.com/f29e36d5-64c8-416c-a57b-ea2ff8c1bf21/screenshot4.jpg');
}
.cardE {Background-image: url('https://ucarecdn.com/2afc45f1-001d-4869-94c3-ca3317dac330/screenshot1.jpg');
}

.card:hover{
  border:1px solid #000000;
}

.card a{
display:block;
width:100%;
height:100%;
}

#container1{ animation-name: card1_ani; }
#container2{ animation-name: card2_ani; }
#container4{ animation-name: card4_ani; }
#container5{ animation-name: card5_ani; }

@keyframes card1_ani {
  0%   { left:0px; transform: rotate(0); }
  100% { left:-50px; transform: rotate(-30deg); }
} 

@keyframes card2_ani {
  0%   { left:0px; transform: rotate(0); }
  100% { left:-30px; transform: rotate(-15deg); }
}

@keyframes card4_ani {
  0%   { left: 0px; transform: rotate(0); }
  100% { left: 30px; transform: rotate(15deg); }
}

@keyframes card5_ani {
  0%   { left:0px; transform: rotate(0); }
  100% { left:50px; transform: rotate(30deg); }
}


/* Styles for screens smaller than 320px (e.g., iPhone 4) */
@media (max-width: 319px) {
  .leftBox {
    grid-template-columns: 220px 60px 60px ;    
  }
}

/* Styles for screens between 320px and 374px (e.g., iPhone 6/7/8) */
@media (min-width: 320px) and (max-width: 374px) {
  .leftBox {
    grid-template-columns: 95px 45px 45px ;    
  }
  .siteId {
    padding: 0 2px 0 2px;
    font-size:1.15rem;
    line-height:3rem;
  }
  .introHead h1, .powerHead h2, .lookHead h2 {font-size: 2.5rem; line-height: 2.5rem;}
  .introTxt, .powerTxt, .lookTxt {width:26ch;}
}

/* Styles for screens between 375px and 413px (e.g., iPhone 6/7/8 Plus) */
@media (min-width: 375px) and (max-width: 413px) {
  .siteId {
    padding: 0 7px 0 7px;
    font-size:2rem;
    line-height:3rem;
  }
  .leftBox {
    grid-template-columns: 155px 45px 45px ;    
  }
  .introHead h1, .powerHead h2, .lookHead h2 {font-size: 3.15rem; line-height: 3.15rem;}
  .introTxt, .powerTxt, .lookTxt {width:31ch;}
}

/* Styles for screens between 414px and 767px (e.g., tablets in portrait orientation) */
@media (min-width: 414px) and (max-width: 767px) {
  .siteId {
    padding: 0 7px 0 7px;
    font-size:2.6rem;
    line-height:3rem;
  }
  .leftBox {
    grid-template-columns: 195px 45px 45px ;    
  }
  .introHead h1, .powerHead h2, .lookHead h2 {font-size: 3.25rem; line-height: 3.25rem;}
  .introTxt, .powerTxt, .lookTxt {width:35ch;}
}

/* Styles for screens between 768px and 1023px (e.g., tablets in landscape orientation) */
/* @media (min-width: 768px) and (max-width: 1023px) { */
@media (min-width: 768px) {
  .siteId {
    padding: 0 7px 0 7px;
    font-size:3rem;
    line-height:3rem;
  }
  .leftBox {
    grid-template-columns: 220px 60px 60px ;
    
  }
  .introHead h1, .powerHead h2, .lookHead h2 {font-size: 3.75rem; line-height: 3.5rem;}
  .introTxt, .powerTxt, .lookTxt {width:42ch;}
}

/* Styles for screens between 1024px and 1279px (e.g., small laptops) */
@media (min-width: 1024px) and (max-width: 1279px) {
  
}

/* Styles for screens larger than 1280px (e.g., larger laptops and desktop monitors) */
@media (min-width: 1280px) {
 
}