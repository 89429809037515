.post {
    display:flex;
    flex-direction: column;    
    margin-left:auto;
    margin-right:auto;
    background-color: var(--post-bk);
    max-width:600px; 
    border: 1px solid; border-color: var(--borders);
    margin-bottom: 45px;
}

.post_image {
    width:100%;
    object-fit: contain;
    border-top:1px solid; border-top-color: var(--borders);
    border-bottom:1px solid; border-bottom-color: var(--borders);
}

.post_text {
    font-weight:normal;
    border-bottom: 1px solid; border-bottom-color: var(--borders);
}

.post_comments {
    padding:20px;
}

.post_header {
    display: flex;
    align-items: center;
    padding:20px;
}

.post_headerInfo {
    display:flex;
    flex:1;
    align-items: center;
    justify-content: space-between;
    margin-left:10px;
}

.post_headerInfo h3 {color: var(--text-primary);}

.post_commentbox {
    display: flex;
    margin-top: 10px;
}

.post_input {
    flex: 1;
    border:none;
    border-top:1px solid; border-top-color: var(--borders);
    padding:10px;
}

.post_button {
    flex: 0;
    border:none;
    border-top:1px solid; border-top-color: var(--borders);
    background-color:transparent;
    color: red;
}

.post_delete svg path {transition: all .5s; stroke: var(--dltIcn); fill: var(--dltIcn);}


h4.post_text {font-weight:bolder; color: var(--text-primary);}
.levels {text-align:center; display:flex; min-height:40px; align-items: center; color: var(--text-primary);} 
.thcLvl, .cbdLvl, .terpLvl  { flex:1; font-weight: bolder;}
.thcLvl .colorBlock, .cbdLvl .colorBlock, .terpLvl .colorBlock {
    color: #e9ecba;
    text-align: center;
    padding:4px; 
    display:inline-block; 
    margin-right:7px; 
    min-width:35%; 
    min-height:100%;
    border-radius: 10px;
    border:2px solid var(--borders);
}


.thcLvl .colorBlock {background-color: var(--clr-1); white-space: nowrap;}
.cbdLvl .colorBlock {background-color: var(--clr-2); white-space: nowrap;}
.terpLvl .colorBlock {background-color: var(--clr-3); white-space: nowrap;}
.thcLvl {color: var(--text-primary);}
.cbdLvl {color: var(--text-primary);}
.terpLvl {color: var(--text-primary);}
.coaAvail {flex:0; font-weight: bolder !important;  text-align: center; color: var(--text-primary) !important;}
.coaAvail:hover {color: var(--clr-5);}
.css-1t5ie6n-MuiAvatar-root {display: none !important;}
.swiper-container {
  }


/* Styles for screens smaller than 320px (e.g., iPhone 4) */
@media (max-width: 319px) {
    h3 {font-size: 1.17rem;}
    h4.post_text {font-size:1.1rem;}
    .post_text {padding:0 20px 20px 20px;}
    .coaAvail {font-size:2rem !important; }
    .levels {padding:4px;}
    .post {width:100%;}
    .thcLvl, .cbdLvl, .terpLvl  { font-size:1rem;}
  }
  
  /* Styles for screens between 320px and 374px (e.g., iPhone 6/7/8) */
  @media (min-width: 320px) and (max-width: 374px) {
    h3 {font-size: 1rem;}
    h4.post_text {font-size:1.1rem;}
    .post_text {padding:0 20px 20px 20px;}
    .coaAvail {font-size:1.8rem !important; }
    .levels {padding:4px;}
    .thcLvl, .cbdLvl, .terpLvl  { font-size:1.1rem;}
    .post {width:100%;}
  }
  
  /* Styles for screens between 375px and 413px (e.g., iPhone 6/7/8 Plus) */
  @media (min-width: 375px) and (max-width: 413px) {
    h3 {font-size: 1rem;}
    h4.post_text {font-size:1.1rem;}
    .post_text {padding:0 20px 20px 20px;}
    .coaAvail {font-size:1.8rem !important; }
    .levels {padding:4px;}
    .thcLvl, .cbdLvl, .terpLvl  { font-size:1.2rem;}
    .post {width:100%;}
  }
  
  /* Styles for screens between 414px and 767px (e.g., tablets in portrait orientation) */
  @media (min-width: 414px) and (max-width: 767px) {
    h3 {font-size: 1rem;}
    h4.post_text {font-size:1.3rem;}
    .post_text {padding:0 20px 20px 20px;}
    .coaAvail {font-size:1.8rem !important; }
    .levels {padding:7px;}
    .thcLvl, .cbdLvl, .terpLvl  { font-size:1.2rem;}
    .post {width:100%;}
  }
  
  /* Styles for screens between 768px and 1023px (e.g., tablets in landscape orientation) */
  @media (min-width: 768px) and (max-width: 1023px) {
    h3 {font-size: 1.17rem;}
    h4.post_text {font-size:1.5rem;}
    .post_text {padding:20px;}
    .coaAvail {font-size:2rem !important; }
    .levels {padding:20px;}
    .thcLvl, .cbdLvl, .terpLvl  { font-size:1.2rem;}
    .post {width:60%;}
  }
  
  /* Styles for screens between 1024px and 1279px (e.g., small laptops) */
  @media (min-width: 1024px) and (max-width: 1279px) {
    h3 {font-size: 1.17rem;}
    h4.post_text {font-size:1.5rem;}
    .post_text {padding:20px;}
    .coaAvail {font-size:2rem !important; }
    .levels {padding:20px;}
    .post {width:60%;}
  }
  
  /* Styles for screens larger than 1280px (e.g., larger laptops and desktop monitors) */
  @media (min-width: 1280px) {
    h3 {font-size: 1.17rem;}
    h4.post_text {font-size:1.5rem;}
    .post_text {padding:20px;}
    .coaAvail {font-size:2rem !important; }
    .levels {padding:20px;}
    .post {width:60%;}
  }